import defaultImage from './default-album-image.png';
import {useTrans} from '@common/i18n/use-trans';
import {message} from '@common/i18n/message';
import {Album} from '@app/landing-page/albums/album';
import clsx from 'clsx';
import {slugifyString} from '@common/utils/string/slugify-string';
import {useNavigate} from '@common/utils/hooks/use-navigate';
interface AlbumImageProps {
  album: Album;
  className?: string;
  size?: string;
}
export function AlbumImage({album, className, size}: AlbumImageProps) {
  const {trans} = useTrans();
  // console.log("album",album);
  const artist = album.artists?.[0];
  const artistName = slugifyString(artist?.name || 'Various Artists');
  const albumName = slugifyString(album.name);
  let link = `/album/${album.id}/${artistName}/${albumName}`;
  const navigate = useNavigate();
  return (
    <img
      className={clsx(className, size, 'object-cover bg-fg-base/4 cursor-pointer')}
      draggable={false}
      loading="lazy"
      src={getAlbumImage(album)}
      alt={trans(message('Image for :name', {values: {name: album.name}}))}
      onClick={() => navigate(link)}
    />
  );
}

export function getAlbumImage(album: Album): string {
  return album?.image || defaultImage;
}

import React, { useState } from 'react';

interface Props {
  isOpen: boolean;
  onClose: () => void; // Function to close the popup
  onConfirm: () => void; // Function to handle the confirmation action
}

const ConfirmUpgradePopup: React.FC<Props> = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) {
    return null; // If isOpen is false, don't render anything
  }

  return (
    <div className='bg-stone-800 h-160 flex align-middle justify-center z-50 absolute top-0 left-0 right-0 bottom-0 rounded-2xl'>
      <div className="text-white bg border-2 border-white/20">
        <div className='p-16'>
          <h2 className='font-bold text-center text-2xl pb-6 pt-6'>Upgrade Subscription</h2>
          <p className='text-center pb-8'>Please note: Your current subscription will be cancelled, and a new one will be created after payment. If nothing happens please refresh your page.</p>
          <div className=' flex items-center justify-center space-x-10 max-w-sm mx-auto'>
            <button onClick={onConfirm} className='font-semibold px-[20px] py-[10px] cursor-pointer rounded-lg border-0 bg-danger hover:bg-dangerlighter'>Confirm</button>
            <button onClick={onClose} className='font-semibold px-[20px] py-[10px] cursor-pointer rounded-lg border-0 bg-danger hover:bg-dangerlighter'>Cancel</button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Styling for the popup overlay
const overlayStyles: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0,0,0,0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex:999
};

// Styling for the popup box
const popupStyles: React.CSSProperties = {
  padding: '20px',
  backgroundColor: '#fff',
  borderRadius: '5px',
  boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
};

// Button styling
const buttonStyles: React.CSSProperties = {
  margin: '10px',
  padding: '10px 20px',
  cursor: 'pointer',
  border: 'none',
  borderRadius: '5px',
  backgroundColor: '#007BFF',
  color: 'white'
};

export default ConfirmUpgradePopup;


import beatsoraLogo from '/var/www/html/resources/client/landing-page/images/beatsoralogopng.png'
import background from '/var/www/html/resources/client/landing-page/images/sampleimagemusicians.png'


const posts = [
    {
      id: 1,
      title: 'Boost your conversion rate',
      href: '#',
      description:
        'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
      imageUrl:
        'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
      date: 'Mar 16, 2020',
      datetime: '2020-03-16',
      category: { title: 'Marketing', href: '#' },
      author: {
        name: 'Andre Hall',
        imageUrl:
          'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      },
    },
    {
        id: 2,
        title: 'Boost your conversion rate',
        href: '#',
        description:
          'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        imageUrl:
          'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
        date: 'Mar 16, 2020',
        datetime: '2020-03-16',
        category: { title: 'Marketing', href: '#' },
        author: {
          name: 'Andre Hall',
          imageUrl:
            'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
      },
      {
        id: 3,
        title: 'Boost your conversion rate',
        href: '#',
        description:
          'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        imageUrl:
          'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
        date: 'Mar 16, 2020',
        datetime: '2020-03-16',
        category: { title: 'Marketing', href: '#' },
        author: {
          name: 'Andre Hall',
          imageUrl:
            'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
      },
      {
        id: 4,
        title: 'Boost your conversion rate',
        href: '#',
        description:
          'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        imageUrl:
          'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
        date: 'Mar 16, 2020',
        datetime: '2020-03-16',
        category: { title: 'Marketing', href: '#' },
        author: {
          name: 'Andre Hall',
          imageUrl:
            'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
      },
      {
        id: 5,
        title: 'Boost your conversion rate',
        href: '#',
        description:
          'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        imageUrl:
          'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
        date: 'Mar 16, 2020',
        datetime: '2020-03-16',
        category: { title: 'Marketing', href: '#' },
        author: {
          name: 'Andre Hall',
          imageUrl:
            'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
      },
      {
        id: 6,
        title: 'Boost your conversion rate',
        href: '#',
        description:
          'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        imageUrl:
          'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
        date: 'Mar 16, 2020',
        datetime: '2020-03-16',
        category: { title: 'Marketing', href: '#' },
        author: {
          name: 'Andre Hall',
          imageUrl:
            'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
      },
  ]

const categories = [
    {
        id: 1,
        href: '#',
        name: "Gear Reviews",
    },
    {
        id: 2,
        href: '#',
        name: "Tutorials",
    },
    {
        id: 3,
        href: '#',
        name: "Marketing",
    },
    {
        id: 4,
        href: '#',
        name: "Career Development",
    },
    {
      id: 5,
      href: '#',
      name: "Beatsora News",
    },
    
]

export function BlogHomePageLayout() {
  return (
    <>
    <div className="relative isolate overflow-hidden bg-gradient-to-r from-orange-600 to-orange-400">
      <img
        className="align-middle w-[1000px] -z-20 object-cover"
        src={background}
        alt="Beatsora"
      />
      <div className="mx-auto max-w-7xl pt-2 pb-2 sm:pb-32 lg:flex lg:py-24 ">
      <div className='mx-auto max-w-7xl drop-shadow-lg'>
          <img
            
            className="mx-auto h-12 w-auto "
            src={beatsoraLogo}
            alt="Your Company"
          />
          <h1 className="mt-2 text-2xl font-bold tracking-tight text-center text-white sm:text-6xl">
          Artist Hub
          </h1>
          <p className="mt-6 text-xl leading-8 text-white">
          Read from our articles to get tips and tricks to record your next banger!
          </p>
          </div>

      </div>
    </div>

    <div className="bg-stone-900 py-12 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
      


        <div className="text-white text-center text-lg pr-4">
            Browse by category
        </div>
        <div className="inline-flex flex-wrap pt-4 items-center mx-auto align-middle">
            {categories.map((category) => (
                <div key={category.id} className="px-2 pt-4 pb-2">
                    <a
                    href={category.href}
                    className="relative z-10 w-[100px] text-center rounded-full bg-orange-600 px-3 py-2 font-medium text-md text-white"
                    >
                    {category.name}
                    </a>
                </div>
            ))
            }
        </div>


        <div className="text-white pt-12  text-center text-lg pr-4">
            Browse recent articles
        </div>
        <div className="mx-auto pt-4 grid max-w-6xl auto-rows-fr grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <>

            <ArticleTile 
              image={post.imageUrl} 
              dateCreated={post.date}
              authorName={post.author.name}
              authorImage={post.author.imageUrl}
              articleLink={post.href}
              articleTitle={post.title}
              category={post.category.title}
              categoryLink={post.category.href}
              />
            
            </>
          ))}
        </div>
      </div>
    </div>
    </>
  )
}


export function ArticleTile ({image, dateCreated, authorName, authorImage, articleLink, articleTitle, category, categoryLink} : {image : any, dateCreated : any, authorName : any, authorImage : any, articleLink : any, articleTitle : any, category : any, categoryLink : any}) {
  return(
    <article
      className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-60 sm:pt-48 lg:pt-60"
    >
      <img src={image} alt="" className="absolute inset-0 -z-10 h-full w-full object-cover" />
      <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
      <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

      <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">
        <time className="mr-8">
          {dateCreated}
        </time>
        <div className="-ml-4 flex items-center gap-x-4">
          <svg viewBox="0 0 2 2" className="-ml-0.5 h-0.5 w-0.5 flex-none fill-white/50">
            <circle cx={1} cy={1} r={1} />
          </svg>
          <div className="flex gap-x-2.5">
            <img src={authorImage} alt="" className="h-6 w-6 flex-none rounded-full bg-white/10" />
            {authorName}
          </div>
        </div>
      </div>
      <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
        <a href={articleLink}>
          <span className="absolute inset-0" />
          {articleTitle}
        </a>
      </h3>
      <div className="pt-2"/>
        <a
          href={categoryLink}
          className="relative z-10 w-[100px] text-center rounded-full bg-orange-600 px-3 py-1.5 font-medium text-sm text-white"
        >
          {category}
        </a>
    </article>
  )
}
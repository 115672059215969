import { Link, Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FormTextField } from '../../ui/forms/input-field/text-field/text-field';
import { Button } from '../../ui/buttons/button';
import { Form } from '../../ui/forms/form';
import { LinkStyle } from '../../ui/buttons/external-link';
import { RegisterPayload, useRegister } from '../requests/use-register';
import { SocialAuthSection } from './social-auth-section';
import { AuthLayout } from './auth-layout/auth-layout';
import { Trans } from '../../i18n/trans';
import { FormCheckbox } from '../../ui/forms/toggle/checkbox';
import { CustomMenuItem } from '../../menus/custom-menu';
import { useRecaptcha } from '../../recaptcha/use-recaptcha';
import { StaticPageTitle } from '../../seo/static-page-title';
import { useSettings } from '../../core/settings/use-settings';
import React from 'react';

declare global {
  interface Window {
    promotekit_referral: any; // Adjust 'any' to the correct type if possible
  }
}


export function RegisterPage() {
  const {
    branding,
    registration: { disable },
    social,
  } = useSettings();
  const { verify, isVerifying } = useRecaptcha('register');

  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const isWorkspaceRegister = pathname.includes('workspace');
  const isBillingRegister = searchParams.get('redirectFrom') === 'pricing';
  const searchParamsEmail = searchParams.get('email') || undefined;
  const [optIn, setOptIn] = React.useState(true);
  const urlParams = new URLSearchParams(window.location.search);
  const redir = urlParams.get('redir');
  let redirParam = "";

  if (redir) {
    redirParam = '?redir='+redir;
  }

  const form = useForm<RegisterPayload>({
    defaultValues: { email: searchParamsEmail },
  });
  const register = useRegister(form);

  if (disable) {
    return <Navigate to={"/login"+redirParam} replace />;
  }

  let heading = <Trans message="Create a new account" />;
  if (isWorkspaceRegister) {
    heading = (
      <Trans
        values={{ siteName: branding?.site_name }}
        message="To join your team on :siteName, create an account"
      />
    );
  } else if (isBillingRegister) {
    heading = <Trans message="First, let's create your account" />;
  }

  const message = (
    <Trans
      values={{
        a: parts => (
          <Link className={LinkStyle} to={"/login"+redirParam}>
            {parts}
          </Link>
        ),
      }}
      message="Already have an account? <a>Sign in.</a>"
    />
  );

  return (
    <AuthLayout heading={heading} message={message} subhead={"Sign up to receive your beats, licenses & partner discounts."}>
      <StaticPageTitle>
        <Trans message="Register" />
      </StaticPageTitle>
      <Form
        form={form}
        onSubmit={async payload => {
          const isValid = await verify();
          if (isValid) {
            // Append the opt-in state to the payload
            payload.opt_in_marketing_emails = optIn; // Ensure you manage this state at a level where it can be accessed here
            register.mutate(payload);
          }
        }}
      >
        {/* Add First Name field */}
        <FormTextField
          className="mb-32"
          name="first_name"
          type="text"
          label={<Trans message="First Name" />}
          required
        />
        {/* Add Last Name field */}
        <FormTextField
          className="mb-12"
          name="last_name"
          type="text"
          label={<Trans message="Last Name" />}
          required
        />

        <div className='text-white text-xs pb-24'>
          Use your <a className='font-semibold text-danger'>legal name </a>, this cannot be changed later.
        </div>

        <FormTextField
          className="mb-32"
          name="email"
          type="email"
          disabled={!!searchParamsEmail}
          label={<Trans message="Email" />}
          required
        />
        {/* <FormTextField
          name="affiliate_code"
          type="hidden"
          value={localStorage.getItem('via_promotekit')}
          label={''}
        /> */}
        <FormTextField
          className="mb-32"
          name="password"
          type="password"
          label={<Trans message="Password" />}
          required
        />
        <FormTextField
          className="mb-32"
          name="password_confirmation"
          type="password"
          label={<Trans message="Confirm password" />}
          required
        />
        <MailchimpOptInCheckbox optIn={optIn} setOptIn={setOptIn} />



        <PolicyCheckboxes />
        <Button
          className="block w-full"
          type="submit"
          variant="flat"
          color="danger"
          size="md"
          disabled={register.isLoading || isVerifying}
        >
          <Trans message="Create account" />
        </Button>
        <SocialAuthSection
          dividerMessage={
            social.compact_buttons ? (
              <Trans message="Or sign up with" />
            ) : (
              <Trans message="OR" />
            )
          }
        />
      </Form>
    </AuthLayout>
  );
}

function PolicyCheckboxes() {
  const {
    registration: { policies },
  } = useSettings();

  if (!policies) return null;

  return (
    <div className="mb-32">
      {policies.map(policy => (
        <FormCheckbox
          key={policy.id}
          name={policy.id}
          className="block mb-4"
          required
        >
          <Trans
            message="I accept the :name"
            values={{
              name: (
                <CustomMenuItem
                  unstyled
                  className={() => LinkStyle}
                  item={policy}
                />
              ),
            }}
          />
        </FormCheckbox>
      ))}
    </div>
  );
}

interface MailchimpOptInCheckboxProps {
  optIn: boolean;
  setOptIn: (value: boolean) => void;
}

function MailchimpOptInCheckbox({ optIn, setOptIn }: MailchimpOptInCheckboxProps) {
  return (
    <div className="mb-32 items-center justify-center">
      <FormCheckbox
        name="opt_in_marketing_emails"
        className="block mb-4 items-center justify-center"
        checked={optIn}
        onChange={() => setOptIn(!optIn)}
        defaultChecked={true}
      >
        <div className='text-white text-xs items-center justify-center '>Want to be updated when new beats are uploaded? Click to opt in to marketing emails.</div>
      </FormCheckbox>
    </div>
  );
}
import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid';
import { ArticleTile } from './hub-home-layout';
import { YouTubePlayer } from '@app/landing-page/social-media-embeds';
import { InstagramEmbed, TikTokEmbed } from 'react-social-media-embed';
import { Spotify } from 'react-spotify-embed'

const thisData = {
  bannerImage: "https://template.canva.com/EAENvp21inc/1/0/1600w-qt_TMRJF4m0.jpg",
  title: "Example Title Example Title Example Title Example Title Example Title Example Title Example Title",
  authorImage: "https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  author: "Andre Hall",
  dateCreated: "20/10/2023",
  category: "Marketing",
  categoryLink: "#",
  description: "Example description Example description Example description Example description Example description Example description Example description Example description Example description Example description Example description Example description",
  section1: "Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 Section 1 ",
  section2: "Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 Section 2 ",
  section3: "Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 Section 3 ",
  section4: "Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 Section 4 ",
  image1: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&auto=format&fit=facearea&w=1310&h=873&q=80&facepad=3",
  image1desc: "Image 1 Sample Description",
  image2: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&auto=format&fit=facearea&w=1310&h=873&q=80&facepad=3",
  image2desc: "Image 2 Sample Description",
  image3: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&auto=format&fit=facearea&w=1310&h=873&q=80&facepad=3",
  image3desc: "Image 3 Sample Description",
}

const posts = [
  {
    id: 1,
    title: 'Boost your conversion rate',
    href: '#',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    imageUrl:
      'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
    category: { title: 'Marketing', href: '#' },
    author: {
      name: 'Andre Hall',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
      id: 2,
      title: 'Boost your conversion rate',
      href: '#',
      description:
        'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
      imageUrl:
        'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
      date: 'Mar 16, 2020',
      datetime: '2020-03-16',
      category: { title: 'Marketing', href: '#' },
      author: {
        name: 'Andre Hall',
        imageUrl:
          'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      },
    },
    {
      id: 3,
      title: 'Boost your conversion rate',
      href: '#',
      description:
        'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
      imageUrl:
        'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
      date: 'Mar 16, 2020',
      datetime: '2020-03-16',
      category: { title: 'Marketing', href: '#' },
      author: {
        name: 'Andre Hall',
        imageUrl:
          'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      },
    },
    
]

export function BlogArticleLayout( {inf} : {inf : any} ) {
  inf = thisData;
  return (
      <>
     

      <div className="bg-stone-900">
        
        {/* FIXED SECTION */}
        <ArticleBanner bannerImage={inf.bannerImage}/>
        <div className='px-4'>
          <ArticleTitle articleTitle={inf.title}/>
          <ArticleAuthorDate authorImage={inf.authorImage} authorName={inf.author} dateCreated={inf.dateCreated} categoryName={inf.category} categoryLink={inf.categoryLink}/>
          <ArticleDescription articleDescription={inf.description}/>

          {/* FLEXIBLE SECTION */}
          <RichTextSection richText={inf.section1}/>
          <LargeImageSection img={inf.image1} desc={inf.image1desc}/>
          <RichTextSection richText={inf.section2}/>
          <YouTubeEmbedSection videoId="n8NzWQhX77k"/>
          <InstagramEmbedSection/>
          <SpotifyEmbedSection/>
          <TikTokEmbedSection/>

          {/* OTHER ARTICLES SECTION */}
          <div className="mx-auto max-w-7xl px-6 lg:px-8">

            <div className="text-white pt-24  text-center text-lg pr-4">
                Browse recent articles
            </div>
            <div className="mx-auto pt-4 grid max-w-6xl auto-rows-fr grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {posts.map((post) => (
                <>
                <ArticleTile 
                  image={post.imageUrl} 
                  dateCreated={post.date}
                  authorName={post.author.name}
                  authorImage={post.author.imageUrl}
                  articleLink={post.href}
                  articleTitle={post.title}
                  category={post.category.title}
                  categoryLink={post.category.href}
                  />
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
        </>

  )
}

function ArticleBanner ({bannerImage} : {bannerImage : any}) {
  return(
  <img
    className="mx-auto bg-gray-50 h-80 w-full object-cover "
    src={bannerImage}
    alt=""
  />
  )
}

function ArticleTitle ({articleTitle} : {articleTitle : any}) {
  return(
    <div className="mx-auto max-w-5xl text-base leading-7 text-white">
      <h1 className="mt-8 text-3xl font-bold tracking-tight text-white sm:text-4xl">{articleTitle}</h1>
    </div>
  )
}

function ArticleAuthorDate ({authorImage, authorName, dateCreated, categoryName, categoryLink} : {authorImage : any, authorName : any, dateCreated : any, categoryName : any, categoryLink : any}) {
  return(
    <div className="mx-auto max-w-5xl text-base leading-7 text-white">
      <figcaption className="mt-6 flex gap-x-4">
        <img
          className="h-6 w-6 flex-none rounded-full bg-gray-50"
          src={authorImage}
          alt=""
        />
        <div className="text-sm leading-6 text-stone-400">
          <strong className="font-semibold text-white">by {authorName}</strong> - {dateCreated}
        </div>
        
      </figcaption>

      <div className='pt-7'>
        <a
          href={categoryLink}
          className="relative z-10 w-[100px] text-center rounded-full bg-orange-600 px-3 py-2 font-medium text-md text-white"
          >
          {categoryName}
        </a>
      </div>
    </div>
  )
}

function ArticleDescription ({articleDescription} : {articleDescription : any}) {
  return(
    <div className="mx-auto max-w-5xl text-base leading-7 text-white">
        <p className="mt-6 text-xl leading-8 text-stone-400">
          {articleDescription}
        </p>
    </div>
  )
}

function RichTextSection ({richText} : {richText : any}) {
  return(
    <div className="mx-auto max-w-5xl text-base leading-7 text-white">
        <p className="mt-6">
            {richText}          
          </p>
    </div>
  )
}

function LargeImageSection ({img, desc} : {img : any, desc : any}) {
  return(
    <div className="mx-auto max-w-5xl text-base leading-7 text-white">
        <figure className="mt-16">
          <img
            className="aspect-video rounded-xl bg-gray-50 object-cover"
            src={img}
            alt=""
          />
          <figcaption className="mt-4 flex gap-x-2 text-sm leading-6 text-gray-500">
            <InformationCircleIcon className="mt-0.5 h-5 w-5 flex-none text-gray-300" aria-hidden="true" />
            {desc}
          </figcaption>
        </figure>
    </div>
  )
} 

function YouTubeEmbedSection({ videoId } : { videoId:any }){
  return(
    <div className="mx-auto text-base leading-7 text-white max-w-3xl">
      <div className='py-16'>
        <YouTubePlayer videoId={videoId}/>
      </div>
    </div>
  )
}

function InstagramEmbedSection({}){
  return(
    <div className="mx-auto max-w-5xl text-base leading-7 text-white flex justify-center">
      <div className='py-16'>
        <InstagramEmbed url="https://www.instagram.com/p/CUbHfhpswxt/" width={328} />
      </div>
    </div>
  )
}

function SpotifyEmbedSection({}){
  return(
    <div className="mx-auto max-w-xl text-base leading-7 text-white flex justify-center">
      <div className='py-16'>
        <Spotify wide link="https://open.spotify.com/track/5ihDGnhQgMA0F0tk9fNLlA?si=4472348a63dd4f83" />
      </div>
    </div>
  )
}

function TikTokEmbedSection({}){
  return(
    <div className="mx-auto max-w-5xl text-base leading-7 text-white flex justify-center">
      <div className='py-16'>
        <TikTokEmbed url="https://www.tiktok.com/@epicgardening/video/7055411162212633903" width={325} />
      </div>
    </div>
  )
}
import { XMarkIcon } from '@heroicons/react/20/solid'

export function BetaBannerModal() {
  return (
    <div className="flex items-center gap-x-18 bg-blue-700 px-18 py-8 sm:px-10 sm:before:flex-1">
      <p className="text-xs md:text-sm leading-6 text-white text-center">
        <a href="/pricing">
          <strong className="font-semibold">Welcome to Beatsora!</strong>
          <svg viewBox="0 0 2 2" className="mx-6 inline h-2 w-2 fill-current" aria-hidden="true">
            <circle cx={1} cy={1} r={1} />
          </svg>
          Get 25% off your first two months using <b>code SAVE25!</b> &nbsp;<span aria-hidden="true">&rarr;</span>
        </a>
      </p>
      <div className="flex flex-1 justify-end">
        {/**
        <button type="button" className="-m-8 p-8 focus-visible:outline-offset-[-4px]">
          <span className="sr-only">Dismiss</span>
          <XMarkIcon className="h-20 w-20 text-white" aria-hidden="true" />
        </button>
         */}
      </div>
    </div>
  )
}
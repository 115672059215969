import {Route} from 'react-router-dom';
import {PricingPage} from './pricing-table/pricing-page';
import React, {Fragment} from 'react';
import {FullPageLoader} from '../ui/progress/full-page-loader';

const BillingPageRoutes = React.lazy(
  () => import('./billing-page/billing-page-routes')
);


const BillingRoute = () => {
  // Check some condition that determines whether to redirect or render the BillingPageRoutes
  const shouldRedirect = true; // Replace this with your actual condition

  if (shouldRedirect) {
    // Redirect to the external link
    window.location.href = 'https://billing.stripe.com/p/login/9AQ4jB9Nwfto77O7ss'; // Replace with your external link
    return null; // This is important to prevent rendering the original content
  }

  // Render the BillingPageRoutes if not redirecting
  return (
    <React.Suspense fallback={<FullPageLoader screen />}>
      <BillingPageRoutes />
    </React.Suspense>
  );
};


 // <React.Suspense fallback={<FullPageLoader screen />}>
          // <CheckoutRoutes />
        // </React.Suspense>
      

const CheckoutRoutes = React.lazy(() => import('./checkout/checkout-routes'));

export const BillingRoutes = (
  <Fragment>
    {/* <Route path="/pricing" element={<PricingPage />} /> */}
    <Route
      path="checkout/*"
      element={  <BillingRoute /> }
    />
    <Route
      path="billing/*"
      element={
        <BillingRoute />
      }
    />
  </Fragment>
);

import React from 'react';

const containsStringInUrl = (url: string, stringList: string[]): boolean => {
  return stringList.some(str => url.includes(str));
};

// Example Usage within a React Component
export function urlChecker(stringList : string[] ) {
    const url = window.location.pathname;

  const result = containsStringInUrl(url, stringList);

  return result;
};


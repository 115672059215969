import { PricingTable } from './pricing-table';
import StripePricingTable from './stripe-pricing-table';
import { Trans } from '@common/i18n/trans';


 export function HomePagePricing() {
    return (<div>
      {/**
       <h1 className="text-3xl md:text-4xl text-center mt-30 md:mt-60 mb-30 font-normal md:font-medium">
                <Trans message="Choose the right plan for you" />
            </h1>
             */}
      <PricingTable />
      </div>
    )
  }




  
import React, { Fragment, ReactNode } from 'react';
import { Tooltip } from 'flowbite-react';
import { LikeButton } from '@app/landing-page/library/like-button';
import { RepostButton } from '@app/landing-page/reposts/repost-button';
import { DialogTrigger } from '@common/ui/overlays/dialog/dialog-trigger';
import { Button } from '@common/ui/buttons/button';
import { ShareIcon } from '@common/icons/material/Share';
import { Trans } from '@common/i18n/trans';
import { MoreHorizIcon } from '@common/icons/material/MoreHoriz';
import { TrackContextDialog } from '@app/landing-page/tracks/context-dialog/track-context-dialog';
import { MediaItemStats } from '@app/landing-page/tracks/media-item-stats';
import { Track } from '@app/landing-page/tracks/track';
import { Album } from '@app/landing-page/albums/album';
import { AlbumContextDialog } from '@app/landing-page/albums/album-context-dialog';
import clsx from 'clsx';
import { ButtonSize } from '@common/ui/buttons/button-size';
import { useIsMobileMediaQuery } from '@common/utils/hooks/is-mobile-media-query';
import { ShareMediaDialog } from '@app/landing-page/sharing/share-media-dialog';
import { IconButton } from '@common/ui/buttons/icon-button';
import { DownloadIcon } from '@common/icons/material/Download';
import { jsPDF } from "jspdf";  // Import jsPDF
import { licenseTextTemplate } from "./track-table/licenseText";
import { DocumentTextIcon } from '@heroicons/react/20/solid';


interface Props {
  item: Track | Album;
  managesItem: boolean;
  buttonClassName?: string;
  buttonGap?: string;
  buttonSize?: ButtonSize;
  buttonRadius?: string;
  children?: ReactNode;
  className?: string;
  showDownloadButtons?: boolean;
  userId?: string;
}

const downloadFileFromUrl = async (url: string) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Network response was not ok ' + response.statusText);
    }
    const blob = await response.blob();
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'track.mp3'; // You might want to dynamically set the file name
    link.click();
  } catch (error) {
    console.error('Download failed:', error);
  }
};




const generatePdfLicense = async (userId: string, trackId: string) => {
  const doc = new jsPDF();

  // Populate the license text using your template
  const populatedLicenseText = licenseTextTemplate
    .replace('{userId}', userId)
    .replace('{trackId}', trackId);

  // Add the license text to the PDF
  doc.setFontSize(10); // Set a font size
  const lineHeight = 7; // Set a line height
  const lines = populatedLicenseText.split('\n'); // Split the text by newline to get an array of lines
  const pageHeight = doc.internal.pageSize.height;  // Get the page height

  let linesOnCurrentPage = 0;

  lines.forEach((line) => {
    const yPosition = 10 + linesOnCurrentPage * lineHeight;
    if (yPosition > pageHeight - 10) {
      doc.addPage();
      linesOnCurrentPage = 0;
    }
    doc.text(line, 10, 10 + linesOnCurrentPage * lineHeight); // Add each line at an increasing y position to form paragraphs
    linesOnCurrentPage += 1;
  });

  // Load the image and add it to the PDF
  const img = new Image();
  img.src = 'https://i.ibb.co/2YNPXhn/beatsora-logo-final.png';
  img.onload = function () {
    doc.addImage(img, 'PNG', 10, doc.internal.pageSize.height - 50, 50, 50);
    // Save the PDF
    doc.save(`License_${userId}_${trackId}.pdf`);
  };
};



export function TrackActionsBar({
  item,
  managesItem,
  buttonClassName,
  buttonGap = 'mr-8',
  buttonSize = 'xs',
  buttonRadius = 'rounded',
  children,
  className,
  showDownloadButtons = false
  ,
  userId,
}: Props) {
  const isMobile = useIsMobileMediaQuery();

  return (
    <div
      className={clsx(
        'flex items-center gap-24 justify-center md:justify-between overflow-hidden @container',
        className
      )}
    >
      <div>
        {children}
        {!isMobile && (
          <Fragment>
            {/* <LikeButton
              size={buttonSize}
              likeable={item}
              className={clsx(buttonGap, buttonClassName)}
              radius={buttonRadius}
              disabled={managesItem}
            />
            <RepostButton
              item={item}
              size={buttonSize}
              radius={buttonRadius}
              disabled={managesItem}
              className={clsx(
                buttonGap,
                buttonClassName,
                'hidden @[840px]:inline-flex'
              )}
            /> */}
          </Fragment>
        )}

        <DialogTrigger type="modal">
          <Button
            size={buttonSize}
            variant="outline"
            startIcon={<ShareIcon />}
            className={clsx(
              buttonGap,
              buttonClassName,
              '@[660px]:inline-flex'
            )}
            radius={buttonRadius}
          >
            <Trans message="Share" />
          </Button>
          <ShareMediaDialog item={item} />
        </DialogTrigger>

        {/* <DialogTrigger type="popover">
          <Button
            variant="outline"
            size={buttonSize}
            startIcon={<MoreHorizIcon />}
            className={clsx(buttonGap, buttonClassName)}
            radius={buttonRadius}
          >
            <Trans message="More" />
          </Button>
          <MoreDialog item={item} />
        </DialogTrigger> */}



        {showDownloadButtons && (

          <div>
            <Tooltip
              content="1 Credit"
              style="light"
            >
              <IconButton
                onClick={async () => {
                  const downloadUrl = `https://www.beatsora.com/api/v1/tracks/${item.id}/wav_downloads`;
                  downloadFileFromUrl(downloadUrl);
                  window.location.reload();
                }}
              >

                <DownloadIcon />

              </IconButton>
              <button onClick={() => generatePdfLicense(userId!, item.id.toString())}>
                <DocumentTextIcon className='text-white w-20 h-auto' />
              </button>
            </Tooltip>
          </div>
        )}


      </div>
      {/* {!isMobile && <MediaItemStats item={item} />} */}

    </div>
  );
}

interface MoreDialogProps {
  item: Track | Album;
}
function MoreDialog({ item }: MoreDialogProps) {
  if (item.model_type === 'track') {
    return <TrackContextDialog tracks={[item]} />;
  }
  return <AlbumContextDialog album={item} />;
}
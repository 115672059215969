import {useMutation} from '@tanstack/react-query';
import {UseFormReturn} from 'react-hook-form';
import {BackendResponse} from '../../http/backend-response/backend-response';
import {onFormQueryError} from '../../errors/on-form-query-error';
import {useNavigate} from '../../utils/hooks/use-navigate';
import {apiClient} from '../../http/query-client';
import {useAuth} from '../use-auth';
import {useBootstrapData} from '../../core/bootstrap-data/bootstrap-data-context';
import {useCallback} from 'react';
import Cookies from 'universal-cookie';



interface LoginResponse extends BackendResponse {
  bootstrapData: string;
  two_factor: false;
}
interface TwoFactorResponse {
  two_factor: true;
}

type Response = LoginResponse | TwoFactorResponse;

export interface LoginPayload {
  email: string;
  password: string;
  remember: boolean;
  token_name: string;
}

export function useLogin(form: UseFormReturn<LoginPayload>) {
  const handleSuccess = useHandleLoginSuccess();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  let redir = urlParams.get('redir');

  return useMutation(login, {
    onSuccess: response => {
      if (!response.two_factor) {
        handleSuccess(response);
      }
    },
    onError: error => {
      let redirectUrl = '/login?loginFailed=true';
      if (redir) {
        redirectUrl += `&redir=${encodeURIComponent(redir)}`;
      }
      navigate(redirectUrl, { replace: false });
      onFormQueryError(error, form);
    },
  });
}

export function useHandleLoginSuccess() {
  const navigate = useNavigate();
  const {getRedirectUri} = useAuth();
  const {setBootstrapData} = useBootstrapData();
  const urlParams = new URLSearchParams(window.location.search);
  const redir = urlParams.get('redir');
  let redirectUrl = getRedirectUri();

  if (redir) {
    redirectUrl = '../'+redir
  } else {
    redirectUrl = '../'+'explore'
  }

  return useCallback(
    (response: LoginResponse) => {
      setBootstrapData(response.bootstrapData);
      navigate(redirectUrl, {replace: true});
      {/**[({redir} ? navigate(redirectUrl, { replace: true }) : navigate), setBootstrapData, getRedirectUri] */}

    },
    
    [navigate(redirectUrl, { replace: true }), setBootstrapData, getRedirectUri]
  );
}

function login(payload: LoginPayload): Promise<Response> {
  return apiClient.post('auth/login', payload).then(response => {
    // console.log(response.data);
    return response.data;
  });
}

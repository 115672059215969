import { Fragment, useState, useEffect, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useLocation } from 'react-router-dom';
import { data } from 'autoprefixer'
import { IconButton, DownloadIconButton } from '@common/ui/buttons/icon-button';
import { DownloadIcon } from '@common/icons/material/Download';
import { Track } from '@app/landing-page/tracks/track';
import { downloadFileFromUrl } from '@app/landing-page/com/uploads/utils/download-file-from-url';
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import sampleLicense from './sample-license.pdf'
import { DownloadButton } from './DownloadButton';
import { PaymentSuccess } from './payment-success';
import { useUser } from '@app/landing-page/com/auth/ui/use-user';
import { loadStripe } from '@stripe/stripe-js';
import { DownloadPurchase } from './DownloadPurcharse';
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import { useAuth } from '@app/landing-page/com/auth/use-auth';
import { MicrophoneIcon, RectangleStackIcon, FilmIcon, RadioIcon, LockOpenIcon, MusicalNoteIcon } from '@heroicons/react/24/solid';
import { LicenseInfoDownloadConfirmation } from '@common/billing/pricing-table/license-info';
import { ContextMenuButton } from '@app/landing-page/context-dialog/context-dialog-layout';
import { Trans } from '@common/i18n/trans';

const stripe_public_key = "pk_live_51NJGqPESKBqwCn2g7RjF2Mehxxvj3kO1rF6hCKGxufN9tChldGXd5iwHkwjqEajVK3GsVIVM4Qkssuy9c7uD1VJA00eV1Bw4uL"; // live
// const stripe_public_key = "pk_test_51POdgYLgPVZjcxHhaEbDq71YvfqscQY6xwddxO7tnChtfM0sPJYRzej6zDNRa98kc0QUTuD1j5wIZDeyRXKWEZKA00BZZdPnvJ";
const stripePromise = loadStripe(stripe_public_key);



const licenseInfo = [
  {
    ability: "What your license includes:",
    list: [
      {
        name: <div>Used for music recording</div>,
        icon: MicrophoneIcon,
      },
      {
        name: <div>Distribute <b>unlimited</b> copies and keep 100% of the royalties</div>,
        icon: RectangleStackIcon,
      },
      {
        name: <div><b>Unlimited</b> online audio streams</div>,
        icon: LockOpenIcon,
      },
      {
        name: <div>Radio broadcasting to <b>unlimited</b> stations</div>,
        icon: RadioIcon,
      },
      {
        name: <div><b>Unlimited</b> music video rights</div>,
        icon: FilmIcon,
      },
      {
        name: <div>For profit live performances</div>,
        icon: MusicalNoteIcon,
      },
    ],
  },
]

export function DownloadAgreement({ track, player = false, from }: { track: Track, player: boolean, from?: String }) {
  if (!track) {
    return;
  }
  const [open, setOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState<string>(''); // State to manage popup message
  const [showTopUpModal, setShowTopUpModal] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [downloadingPurchase, setDownloadingPurchase] = useState(false);
  const [success, setSuccess] = useState(false);
  const { data: userData } = useUser('me');
  const location = useLocation();
  const { isSubscribed } = useAuth();
  const token = localStorage.getItem('access_token');

  // console.log('subisSubscribed',isSubscribed)
  // console.log('trakkkkkk', track)



  /// download track via direct payment
  const params = new URLSearchParams(location.search);

  const downloadPaymentSuccess = params.has('download_payment_success');
  const id = params.get('download_payment_success');


  useEffect(() => {
    if (downloadPaymentSuccess) {
      const id = params.get('download_payment_success');

      const token = localStorage.getItem('access_token');

      const fetchData = async () => {
        try {
          const response = await fetch('/api/v1/stripe/success', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ price_cost: track.price_cost, track_id: id }),
          });

          if (response.ok) {
            const responseData = await response.json();
            const fileExists = responseData.file_exists;
            if (track.wav_downloads && fileExists) {
              downloadFileFromUrl(`/api/v1/tracks/${id}/wav_downloads`);
              setSuccess(true);
              setPopupMessage(track.already_downloaded ? 'Download successful!' : 'Download successful!');
              setShowTopUpModal(true);

            } else {
              setSuccess(false);
              setPopupMessage('WAV file is not available for download.');
            }
            setDownloadingPurchase(false);
            setShowTopUpModal(true);
          }
        } catch (error) {
          setDownloadingPurchase(false);
          console.error('Error:', error);
        }
      };
      if (id) {
        fetchData();
      }

    }

  }, [id]);




  // Function to handle download button click
  const handleDownloadPay = async (e: any) => {
    e.preventDefault();

    const token = localStorage.getItem('access_token');
    setDownloadingPurchase(true);
    setSuccess(false);

    if (!userData?.user) {
      window.location.href = "/login";
    }
    //setSuccess(true);
    if (track.already_downloaded) {
      downloadFileFromUrl(`/api/v1/tracks/${track.id}/wav_downloads`);
      setPopupMessage('Download successful!');
      setShowTopUpModal(true);
      setDownloadingPurchase(false);
      setSuccess(true);
      return;
    }



    try {
      const stripe = await stripePromise;
      if (!stripe) {
        console.error('Stripe failed to initialize');
        return;
      }
      let price_cost = 2499; // cent
      let id = track?.id;
      const response = await fetch('/api/v1/stripe/pay/track', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ price_cost, id }),
      });

      const session = await response.json();

      const result = await stripe.redirectToCheckout({ sessionId: session.sessionId });

      if (result.error) {
        setDownloadingPurchase(false);
        alert(result.error.message);
      }

      //setOpen(true);
    } catch (error) {
      setDownloadingPurchase(false);
      console.error('Error occurred:', error);
      setPopupMessage('An error occurred. Please try again later.');
      setShowTopUpModal(true);
      setOpen(true);
    }
    setSuccess(false);
  };

  /// download track via direct payment

  const handleDownload = async (e: any) => {
    e.preventDefault();

    const token = localStorage.getItem('access_token');
    setDownloading(true);
    setSuccess(false);

    if (!userData?.user) {
      window.location.href = "/pricing";
    }

    try {
      const response = await fetch(`/api/v1/wallet/deduct/${track.id}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      setDownloading(false);
      if (response.ok) {
        const responseData = await response.json();
        const fileExists: boolean = responseData.file_exists;

        if (track.wav_downloads && fileExists) {
          downloadFileFromUrl(`/api/v1/tracks/${track.id}/wav_downloads`);
          setSuccess(true);
          if (track.already_downloaded) {
            setPopupMessage('Download successful!');
          } else {
            setPopupMessage('Credits deduction and download successful!');
          }
          setShowTopUpModal(true);
        } else {
          setSuccess(false);
          setPopupMessage('WAV file is not available for download.');
          setShowTopUpModal(true);
        }
      } else {
        setSuccess(false);
        setPopupMessage('Could not deduct credits. Please check your wallet.');
        setShowTopUpModal(true);
      }

      // Open the dialog to show the pop-up message
      setOpen(true);
    } catch (error) {
      console.error('Error occurred:', error);
      setPopupMessage('An error occurred. Please try again later.');
      setShowTopUpModal(true);
      // Open the dialog to show the pop-up message
      setOpen(true);
    }
    setSuccess(false);
  };

  return (
    <>
      <div>
        {from ? (
          <ContextMenuButton onClick={() => setOpen(true)}>
            <Trans message="Download Track" />
          </ContextMenuButton>
        ) : (
          <a className="" onClick={() => setOpen(true)}>
            <div className={`inline-flex bg-danger text-white hover:bg-dangerdarker rounded-lg py-3 px-3 items-center hover:cursor-pointer`}>
              {!player && <div className='pr-6 hidden lg:block text-sm font-semibold'>$14.99</div>}
              <DownloadIcon />
            </div>
          </a>
        )}



        <PaymentSuccess
          show={showTopUpModal}
          message={popupMessage}
          onClose={() => {
            setShowTopUpModal(false);
          }
          }
          onTopUp={(tier) => {
            setShowTopUpModal(false);
          }} />

        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-popover" onClose={setOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                      <div className="flex h-full flex-col overflow-y-scroll bg-gray-900 py-6 shadow-xl px-16">
                        <div className="">
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="text-xl font-semibold leading-6 mt-18 text-white">
                              Download Track:
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={() => setOpen(false)}
                              >
                                <span className="absolute -inset-2.5" />
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon className="h-20 w-20" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="relative pt-16 flex-1 px-4 sm:px-6">
                          <div className='text-white'>
                            {/**
                          <div className='font-semibold pb-8'>Track Name:</div> */}
                            {track.name}
                          </div>
                          <img className="w-full h-auto rounded-lg px-48 md:px-80 py-16" src={track.image} />

                          <Disclosure as="div" className="pt-6">
                            {({ open }) => (
                              <>
                                <dt>

                                  <Disclosure.Button className="flex w-full items-center justify-between text-left text-white hover:bg-white/10 rounded-md">
                                    <div className="flex gap-x-8 px-10 py-4 items-center">
                                      <span className="text-lg font-semibold leading-7 text-center">License Information</span>
                                    </div>
                                    <span className="pr-6 flex h-20 items-center">
                                      {open ? (
                                        <MinusSmallIcon className="h-18 w-18" aria-hidden="true" />
                                      ) : (
                                        <PlusSmallIcon className="h-18 w-18" aria-hidden="true" />
                                      )}
                                    </span>

                                  </Disclosure.Button>

                                </dt>
                                <Disclosure.Panel as="dd" className="">
                                  <LicenseInfoDownloadConfirmation />
                                </Disclosure.Panel>

                              </>
                            )}
                          </Disclosure>



                          <form onSubmit={handleDownload}>
                            <div className='pb-8'>

                              <div className='text-white pt-8 text-xs text-center'>
                                By downloading this beat, you acknowledge that you will adhere to the limitations outlined in your <a target="_blank" href={sampleLicense} className='font-semibold text-dangerlighter'>'License'.</a>, and that any infringement will violate our <a target="_blank" href="https://app.termly.io/document/terms-of-service/e2595200-267e-4272-9008-c22ffc0ab4a3" className='font-semibold text-dangerlighter'>'Terms & Conditions'.</a>
                              </div>

                            </div>
                            <div className='px-4'>
                              <DownloadButton downloading={downloading} success={success} already_downloaded={track.already_downloaded} amount={1} />
                            </div>

                          </form>

                          <div className='text-center text-sm font-bold mx-auto pt-6 mt-2 text-white'>
                            OR
                          </div>
                          {/* use this for direct pay*/}
                          <form onSubmit={handleDownloadPay}>
                            <div className='px-4'>
                              <DownloadPurchase downloading={downloadingPurchase} success={success} already_downloaded={track.already_downloaded} amount={track.price_cost} />
                            </div>
                          </form>

                          {!token && !track.already_downloaded && (
                            <>
                              <div className="pt-24" />
                              <div className='px-24 border-2 border-white/30 rounded-lg'>
                                <div className='py-10'>
                                  <div className='pb-10 px-20 text-center text-white font-semibold mx-auto'>
                                    Get 2 beats, track feedback, AI mastering, AI lyrics, vocal presets and discounts with our partners for $14.99 per month.
                                  </div>
                                  <a href='/pricing' className='mx-auto w-180 align-middle h-48 bg-blue-600 hover:bg-blue-700 text-white shadow-sm mt-8 block rounded-md py-4 px-3 text-center text-lg font-semibold leading-6'>
                                    <div className='pt-6 align-middle items-center'>
                                      <Fragment>See plans</Fragment>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </>
                          )}
                        </div>

                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </>
  );
} 

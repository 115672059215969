import { RegisterPage } from './ui/register-page';
import { AuthRoute } from './guards/auth-route';
import { AccountSettingsPage } from './ui/account-settings/account-settings-page';
import { GuestRoute } from './guards/guest-route';
import { ForgotPasswordPage } from './ui/forgot-password-page';
import { ResetPasswordPage } from './ui/reset-password-page';
import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import { LoginPageWrapper } from '@common/auth/ui/login-page-wrapper';
import { TestBlogPage } from '@common/footer-pages/testblog';
import { DebugPage } from '@common/footer-pages/debug';

// import { HelpCentreLayout } from '@common/help-centre/help-centre-page';
// import { DedicatedExplorePage } from '@app/web-player/channels/dedicated-explore-page';
// import { PricingLayoutPage } from '@common/billing/pricing-table/pricing-layout-page';
// import { ProfileDownloadsPanel } from '@app/web-player/user-profile/panels/profile-downloads-panel';
// import { DownloadsRedirector } from '@app/web-player/user-profile/user-profile-page';
// import { AboutUsPageLayout } from '@common/footer-pages/about-us-page';
// import { PartnersPageLayout } from '@common/footer-pages/partners-page';
// import { ComingSoonPageLayout } from '@common/footer-pages/coming-soon-page';
// import { AIMasteringPageLayout } from '@common/ai-mastering/ai-mastering-page-layout';





export const AuthRoutes = (
  <>
    {/* <Fragment> */}
    <Route path="/register" element={<RegisterPage />} />
    <Route path="/testblog" element={<TestBlogPage />} />
    <Route path="/debug" element={<DebugPage />} />
    {/* <Route
      path="/feedback-form"
      element={<FeedbackPageLayout />}
    /> */}

    {/* <Route path="/helpcentre" element={<HelpCentreLayout />} /> */}
    {/* <Route path="/explore" element={<DedicatedExplorePage />} /> */}
    {/* <Route path="/pricinglayout" element={<PricingLayoutPage />} /> */}
    {/* <Route path="/profile/downloads" element={<DownloadsRedirector />} /> */}
    {/* <Route path="/about" element={<AboutUsPageLayout />} /> */}
    {/* <Route path="/partners" element={<PartnersPageLayout />} /> */}
    {/* <Route path="/comingsoon" element={<ComingSoonPageLayout />} /> */}
    {/* <Route path="/aimastering" element={<AIMasteringPageLayout />} /> */}

    <Route
      path="/account-settings"
      element={
        <AuthRoute>
          <AccountSettingsPage />
        </AuthRoute>
      }
    />
    <Route
      path="login"
      element={
        <GuestRoute>
          <LoginPageWrapper />
        </GuestRoute>
      }
    />
    <Route
      path="/workspace/join/register"
      element={
        <GuestRoute>
          <RegisterPage />
        </GuestRoute>
      }
    />
    <Route
      path="/workspace/join/login"
      element={
        <GuestRoute>
          <LoginPageWrapper />
        </GuestRoute>
      }
    />
    <Route
      path="forgot-password"
      element={
        <GuestRoute>
          <ForgotPasswordPage />
        </GuestRoute>
      }
    />
    <Route
      path="/password/reset/:token"
      element={
        <GuestRoute>
          <ResetPasswordPage />
        </GuestRoute>
      }
    />
    {/*  </Fragment> */}
  </>
);

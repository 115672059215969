import { useProducts } from './use-products';
import { Button } from '../../ui/buttons/button';
import { Trans } from '../../i18n/trans';
import { ForumIcon } from '../../icons/material/Forum';
import { Navbar } from '../../ui/navigation/navbar/navbar';
import { Link } from 'react-router-dom';
import { Footer } from '../../ui/footer/footer';
import { Fragment, useState } from 'react';
import { UpsellBillingCycle } from './find-best-price';
import { BillingCycleRadio } from './billing-cycle-radio';
import { StaticPageTitle } from '../../seo/static-page-title';
import { PricingTable } from '@common/billing/pricing-table/pricing-table';
import { LayoutPricing } from '@common/billing/pricing-table/pricing-layout-table';
import { NavSideView } from '@app/web-player/layout/nav-side-view';
import { LicenseInfoSection } from '@common/billing/pricing-table/license-info';

import { Disclosure, RadioGroup } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

export function PricingLayoutPage() {
    const query = useProducts();
    const [selectedCycle, setSelectedCycle] =
        useState<UpsellBillingCycle>('yearly');

    return (
        <>
        {/**
        <Fragment>
            <StaticPageTitle>
                <Trans message="Pricing" />
            </StaticPageTitle>
            <Navbar
                color="bg"
                darkModeColor="transparent"
                border="border-b"
                menuPosition="pricing-table-page"
            />
            <div className="container mx-auto px-24">
                <h1 className="text-3xl md:text-4xl text-center mt-30 md:mt-60 mb-30 font-normal md:font-medium">
                    <Trans message="Choose the right plan for you" />
                </h1>

                <LayoutPricing />
                <ContactSection />
            </div>
            <Footer className="container mx-auto px-24 flex-shrink-0" />
        </Fragment>
         */}

        <Fragment>
            <StaticPageTitle>
                <Trans message="Pricing" />
            </StaticPageTitle>

            {/* <NavSideView section={<Main/>}/> */}
            <Main />
        </Fragment>
        </>
    );
}

function Main() {
    return(
        <div className="container mx-auto px-24">
            <LayoutPricing />
            <LicenseInfoSection/>
            <ContactSection />
        </div>
    )
}



const faqs = [
    {
    question: "Can I cancel at any time?",
    answer:
        "Yes! You can cancel at any time! When you cancel a paid plan, you will have access until your next billing cycle, but you will keep any unused credits.",
    },
    {
    question: "Can I upgrade or downgrade my plan?",
    answer:
        "Yes! You can change your subscription plan at any time. New credits will be assigned at the end of your next billing cycle.",
    },
    {
      question: "Are beats royalty free?",
      answer:
        "Yes. You have a royalty-free license for every beat that you download from Beatsora.com. Please be aware that most distributors still require you to disclose the use of any licensed third party materials in your content, even if they are royalty-free. A certified license from Beatsora will be provided via email for this purpose.",
    },
    {
      question: "Are the beats exclusive?",
      answer:
        "Beatsora is a platform designed to provide aspiring and emerging artists with affordable access to high quality beats & Instrumentals. As a result, we do not provide exclusive beat licenses.",
    },
    {
    question: "How do credits work?",
    answer:
        "Credits are added to your account each month. When you download a beat, you use a credit. Future downloads of that beat won’t cost you any credits, it’s yours. Credits you don’t use will roll over to your next month.",
    },
    {
    question: "What if I have run out of credits for the month?",
    answer:
        "You can get access to more credits instantly by purchasing credit top ups. These credits are applied instantly one time and have no effect on your monthly allocation of credits.",
    },
  
  ]

export function ContactSection() {
    return (
        <>
            <div className="mx-auto max-w-3xl pb-48 ">
            <p className="text-3xl pt-48 pb-24 text-center mx-auto font-bold tracking-tight text-white sm:text-4xl">FAQ</p>
            <div className="mx-auto divide-y divide-white/40 px-20">
            <dl className="mt-2 space-y-6 divide-y divide-white/40">
                {faqs.map((faq) => (
                <Disclosure as="div" key={faq.question} className="pt-20">
                    {({ open }) => (
                    <>
                        <dt>
                        <Disclosure.Button className="flex w-full items-start justify-between text-left text-white">
                            <span className="text-lg font-semibold leading-7 pb-16">{faq.question}</span>
                            <span className="ml-6 flex h-32 items-center">
                            {open ? (
                                <MinusSmallIcon className="h-20 w-20" aria-hidden="true" />
                            ) : (
                                <PlusSmallIcon className="h-20 w-20" aria-hidden="true" />
                            )}
                            </span>
                        </Disclosure.Button>
                        </dt>
                        <Disclosure.Panel as="dd" className="pb-8 pr-36">
                        <p className="text-base leading-7 pl-16 text-gray-300">{faq.answer}</p>
                        </Disclosure.Panel>
                    </>
                    )}
                </Disclosure>
                ))}
            </dl>


            </div>
        </div>


        <div className="mx-auto max-w-4xl text-center pb-16 mt-2 text-2xl font-bold tracking-tight text-white">
            Can't find what you're looking for? Check out our help center.
        </div>
        <a href="/helpcentre" className='mx-auto w-180 align-middle h-48 bg-danger hover:bg-dangerlighter  text-white shadow-sm mt-8 block rounded-md py-4 px-3 text-center text-lg font-semibold leading-6'>
            <div className='pt-8'>
            Help Center
            </div>
        </a>

        <div className='pt-48' />

        
        {/**
        <div className="p-24 text-center my-20 md:my-80">
            <ForumIcon size="xl" className="text-muted" />
            <div className="md:text-lg my-8">
                <Trans message="Do you have any questions about PRO accounts?" />
            </div>
            <div className="mb-24 text-sm md:text-base mt-20 md:mt-0">
                <Trans message="Our support team will be happy to assist you." />
            </div>
            <Button variant="flat" color="danger" elementType={Link} to="/contact">
                <Trans message="Contact us" />
            </Button>
        </div>
         */}
         </>
    );
}

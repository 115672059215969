import { useMutation } from '@tanstack/react-query';
import { UseFormReturn } from 'react-hook-form';
import { BackendResponse } from '../../http/backend-response/backend-response';
import { onFormQueryError } from '../../errors/on-form-query-error';
import { useNavigate } from '../../utils/hooks/use-navigate';
import { apiClient } from '../../http/query-client';
import { useAuth } from '../use-auth';
import { useBootstrapData } from '../../core/bootstrap-data/bootstrap-data-context';

interface Response extends BackendResponse {
  bootstrapData?: string;
  message?: string;
  status: 'success' | 'needs_email_verification';
  opt_in_marketing_emails: boolean; // Add this line

}

export interface RegisterPayload {
  opt_in_marketing_emails: any;
  email: string;
  password: string;
  password_confirmation: string;
}

export function useRegister(form: UseFormReturn<RegisterPayload>) {
  const navigate = useNavigate();
  const { getRedirectUri } = useAuth();
  const { setBootstrapData } = useBootstrapData();
  const urlParams = new URLSearchParams(window.location.search);
  const redir = urlParams.get('redir') || sessionStorage.getItem('preRegisterRedirect'); // Get redirect from session storage if not in URL

  return useMutation(register, {
    onSuccess: response => {
      setBootstrapData(response.bootstrapData!);

      if (response.status === 'success') {
        // Navigate to account success page
        navigate('/accountsuccess', { replace: true });

        // Set a timeout to go back to the original page or default URI after showing success message
        setTimeout(() => {
          if (redir) {
            navigate(redir, { replace: true });
          } else {
            navigate(getRedirectUri(), { replace: true });
          }
        }, 3000);  // Delay of 3000 milliseconds before redirecting
      } else if (response.status === 'needs_email_verification') {
        navigate('/'); // Navigate to home if email verification is needed
      }
    },
    onError: r => onFormQueryError(r, form),
  });
}

function register(payload: RegisterPayload): Promise<Response> {
  return apiClient
    .post('auth/register', payload)
    .then(response => response.data);
}

// Add this where the register link/button is triggered
const handleRegisterClick = () => {
  sessionStorage.setItem('preRegisterRedirect', window.location.href); // Store the current URL before moving to register page
};
import React from 'react';
import YouTube from 'react-youtube';

interface YouTubePlayerProps {
  videoId: string;
}

export function YouTubePlayer({ videoId }: YouTubePlayerProps) {
  const opts = {
    playerVars: {
      autoplay: 0,
    },
  };

  // Set up event handlers
  const onReady = (event: any) => {
    // Access the player instance
    const player = event.target;
  };

  const onError = (error: any) => {
    console.error('YouTube Player Error:', error);
  };

  return (
    <div className="relative w-full pb-[56.25%]">
      <YouTube
        videoId={videoId}
        onReady={onReady}
        onError={onError}
        opts={opts}
        iframeClassName="absolute top-0 left-0 w-full h-full"
      />
    </div>
  );
}
import { UseQueryResult } from '@tanstack/react-query';
import { Helmet } from '@common/seo/helmet';
import { DefaultMetaTags } from '@common/seo/default-meta-tags';
import React from 'react';
import { BackendResponse } from '@common/http/backend-response/backend-response';

interface Props {
  query: UseQueryResult<BackendResponse>;
}
export function PageMetaTags({ query }: any) {
  let seo = query.data?.seo ?? query?.seo
  return seo ? (
    <Helmet tags={seo} />
  ) : (
    <DefaultMetaTags />
  );
}

import { Track } from '@app/landing-page/tracks/track';
import { MediaItem } from '@app/landing-page/com/player/media-item';
import { getTrackImageSrc } from '@app/landing-page/tracks/track-image/track-image';
import { Album } from '@app/landing-page/albums/album';
import { guessPlayerProvider } from '@app/landing-page/com/player/utils/guess-player-provider';

export function trackToMediaItem(
  track: Track,
  queueGroupId?: string | number
): MediaItem<Track> {
  const provider: MediaItem['provider'] = track.src
    ? guessPlayerProvider(track.src)
    : 'youtube';

  if (!track.src || provider === 'youtube') {
    return {
      id: track.id,
      provider: 'youtube',
      meta: track,
      src: track.src ? track.src : 'resolve',
      groupId: queueGroupId,
    };
  }

  return {
    id: track.id,
    src: track.src,
    provider,
    meta: track,
    poster: getTrackImageSrc(track),
    groupId: queueGroupId,
  };
}

export function tracksToMediaItems(
  tracks: Track[],
  queueGroupId?: string,
  album?: Album
) {
  return tracks.map(track => {
    if (album && !track.albums) {
      track = {
        ...track,
        albums: track.albums ? [...track.albums, { ...album, tracks: undefined }] : [{ ...album, tracks: undefined }]
      };
    }
    return trackToMediaItem(track);
  });
}

// licenseText.ts
export const licenseTextTemplate = `
  The Licensee and Licensor have agreed to the following terms:

  Master Use
  
  The Licensor (Beatsora.com) hereby grants to Licensee ({fullName}) a non-exclusive License 
  to record vocal synchronization to the Composition ({trackId}) partly or 
  in its entirety and substantially in its original form (“Master Recording”).

  Ownership

  The Licensor maintains 100% full rights (copyright, publishing and ownership) 
  of the Composition, and can continue to sell it non-exclusively and/or exclusively. 
  The Licensee has neither the right nor authority to sell or license the rights to 
  the Composition whether in whole or part to any other party. In the event another 
  party purchases exclusive rights to the Composition from the Licensor, the Licensee 
  will retain non-exclusive rights under the limitations listed in this agreement 
  and until these terms have been fulfilled.

  Mechanical Rights

  The Licensor hereby grants to Licensee a non-exclusive License 
  to use Master Recording in the reproduction, duplication, 
  manufacture, and distribution of phonograph records, cassette tapes, compact disks, internet downloads, 
  other and miscellaneous audio and digital recordings, and any lifts and versions thereof (collectively ,
  ”Recordings”) worldwide such Recordings or any combination of such Recordings. Additionally, Licensor
  shall be permitted to distribute unlimited internet downloads for non-profit and non-commercial use.

  Performance Rights

  The Licensor hereby grants to Licensee a non-exclusive License 
  to use the Master Recording in unlimited non-profit performances, 
  shows, or concerts. The Licensee is also permitted to use 
  the Master Recording in unlimited for-profit performances.

  Music Videos

  The Licensor hereby grants to Licensee a non-exclusive License 
  to use the Master Recording in unlimited music videos.

  Synchronization Rights

  The Licensor Does not grant a Licensee to copy, perform, edit and/or 
  loop portions of, record on film, video, digital animations, and 
  video games (collectively, “Projects”) and use the Master Recording
  in synchronization or timed relation with the productions in one (1) Projects.
  
  Credit

  Licensee shall acknowledge the original authorship of the Composition 
  appropriately and reasonably in all media and performance formats 
  by acknowledging the relevant author in writing where possible and 
  vocally otherwise (ie. Prod. by Beatsora). Where a project is 
  commercially released and registered with a performance rights 
  organisation, Licensor shall be acknowledged as a Writer.
  
  Sampling

  The Licensee agrees that the Composition is purchased as a 
  “Work Made for Hire” whereby the clearing of any 
  sampled materials is the responsibility of Licensee.
  
  Compensation
  
  Payment for this License is non-refundable. 
  If the Licensee fails to account to the Licensor, timely and
  complete the payments provided for hereunder, the Licensor 
  shall have the right to terminate this License upon written 
  notice to the Licensee. Such termination shall render the 
  recording, manufacture and/or distribution of Recordings 
  for which monies have not been paid subject to and 
  actionable as infringements under applicable law.
  
  Term
  
  Executed by the Licensor and the Licensee, this License 
  agreement is to be effective as for all purposes as of 
  the Effective Date for a period their subscription is active. 
  In the event that an exclusive license is sold by Licensor
  to the Composition, the terms of this agreement shall be upheld.

  Indemnification

  Licensee agrees to indemnify and hold Licensor harmless 
  from and against any and all claims, losses, damages, costs, 
  and expenses, including, without limitation, reasonable 
  attorneys’ fees, arising out of or resulting from a claimed 
  breach of any of Licensee’s representations, warranties 
  or agreements hereunder.
 
  Miscellaneous
  
  This License is non-transferable and is limited to the
  Composition specified, constitutes the entire agreement 
  between the Licensor and the Licensee relating to the 
  Composition, and shall be binding upon both Licensor 
  and Licensee and their respective successors, assigns, 
  and legal representatives

  Governing Law

  This License is governed by and shall be construed
   under the laws of the United Kingdom, without regard 
   to the conflicts of laws and principles thereof. 

  Publishing

  Licensor maintains all publishing rights.


  `;